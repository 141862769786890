import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import React, { Suspense, useCallback, useEffect } from 'react';
import { IoSearchOutline } from 'react-icons/io5';
// eslint-disable-next-line no-restricted-imports
import { useHistory, useLocation, useParams } from 'react-router-dom';

import createInitialTransientDocumentData from '../../../../shared/foreground/createInitialTransientDocumentData';
import { useDocumentsFromSearchResults } from '../../../../shared/foreground/database/helperHooks';
import { CancelStateUpdate, globalState, updateState } from '../../../../shared/foreground/models';
import background from '../../../../shared/foreground/portalGates/toBackground/singleProcess';
import { setFocusedDocumentId } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { toggleHideRightSidebar } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/sidebars';
import {
  useContentIndexingProgress,
} from '../../../../shared/foreground/useContentIndexingProgress';
import isComposing from '../../../../shared/foreground/utils/isComposing';
import useStatePlusLiveValueRef from '../../../../shared/foreground/utils/useStatePlusLiveValueRef';
import { ShortcutId } from '../../../../shared/types';
import makeLogger from '../../../../shared/utils/makeLogger';
import { useHotKeysPreventDefault, useIsRightSidebarHidden } from '../../hooks/hooks';
import { reactLazy } from '../../utils/dynamicImport';
import { useShortcutsMap } from '../../utils/shortcuts';
import { FloatingPill } from '../FloatingPill';
import ToggleRightPanelIcon from '../icons/ToggleRightPanelIcon';
import StandardPage from '../StandardPage';
import Tooltip from '../Tooltip';
import { useSearch } from './hooks';
import styles from './SearchPage.module.css';

const logger = makeLogger(__filename);

const Documents = reactLazy(() => import('../Documents'));

export const ToggleShowPannelsButton = (): JSX.Element => {
  const shortcutsMap = useShortcutsMap();
  const shortcut = shortcutsMap[ShortcutId.HideRightPanel];

  return (
    <Tooltip content="Show right panel" shortcut={shortcut}>
      <button
        className={[
          styles.actionButton,
          styles.hidePanelsButton,
        ].join(' ')}
        tabIndex={-1}
        onClick={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
          toggleHideRightSidebar({ userInteraction: 'click' });
        }}
        type="button"
      >
        <ToggleRightPanelIcon />
      </button>
    </Tooltip>
  );
};

export const SearchPage = React.memo(function SearchPage() {
  const { openDocumentId } = useParams<{ openDocumentId?: string; }>();
  const isOnline = globalState(useCallback((state) => state.isOnline, []));

  // search
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { query, setQuery, documentResults, contentResults } = useSearch(searchParams, 'q');

  useEffect(() => {
    // Trigger all document content to be loaded if it hasn't been yet
    background.populateContentSearch();
  }, []);

  const {
    mergedDocIds,
    contentResultDocuments,
    isFetchingContentResultDocuments,
  } = useDocumentsFromSearchResults({ contentResults, documentResults });

  useEffect(() => {
    if (contentResultDocuments.length === 0 || isFetchingContentResultDocuments) {
      return;
    }

    // TODO: check whether any documents from contentResults are missing in contentResultDocuments.
    updateState((state) => {
      const contentResultForParsedDocId = keyBy(contentResults, ({ id }) => id?.toString() ?? '');
      for (const doc of contentResultDocuments) {
        if (!doc.parsed_doc_id) {
          logger.error(
            'Document has no parsed_doc_id',
            { doc },
          );
          continue;
        }
        const parsedDocId = doc.parsed_doc_id.toString();
        const contentResult = contentResultForParsedDocId[parsedDocId];
        if (!contentResult) {
          logger.error(
            'Could not find content result',
            { doc, parsedDocId, contentResultForParsedDocId },
          );
          continue;
        }
        const newTransientData = {
          ...state.transientDocumentsData[doc.id] ?? createInitialTransientDocumentData(),
          searchPreviewText: contentResult.text,
        };
        if (isEqual(state.transientDocumentsData[doc.id], newTransientData)) {
          throw new CancelStateUpdate();
        }
        state.transientDocumentsData[doc.id] = newTransientData;
      }
    }, {
      userInteraction: null,
      eventName: 'transient-data-with-search-preview-text-updated',
    });
  }, [contentResultDocuments, contentResults, isFetchingContentResultDocuments]);

  useEffect(() => {
    if (!openDocumentId) {
      setFocusedDocumentId(mergedDocIds?.[0] ?? null, { userInteraction: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergedDocIds]);

  logger.debug('Rendering SearchPage', { contentResults, documentResults });

  const { contentIndexingMessage, percentIndexed } = useContentIndexingProgress(query, isOnline);
  const contentIndexingIndicator = contentIndexingMessage ? <div className={styles.contentIndexingIndicator}>
    <p>
      {contentIndexingMessage}
    </p>
    <p className={styles.percentIndexedIndicator}>
      {percentIndexed !== undefined && <>{percentIndexed}% Indexed</>}
    </p>
  </div> : null;

  let noResultsMessage;
  if (documentResults.length + contentResults.length === 0) {
    const noResultsText = query ? 'No results' : '';
    noResultsMessage = noResultsText ? <p id="no-search-results">{noResultsText}</p> : null;
  }

  return <StandardPage isSidebarHidden={Boolean(openDocumentId)}>
    <div className={`${styles.searchContainer} ${openDocumentId ? styles.hasOpenedDoc : ''}`}>
      {!openDocumentId &&
        <>
          <Header query={query} setQuery={setQuery} />
          {(noResultsMessage || contentIndexingIndicator) && <div className={styles.messageWrapper}>
            {noResultsMessage}
            {contentIndexingIndicator}
          </div>}
        </>
      }

      <Suspense fallback={null}>
        <Documents
          documentPathPrefix="/search"
          listedDocumentIds={mergedDocIds}
          openDocumentId={openDocumentId}
          parentPath="/search"
          showSearchPreview
        />
      </Suspense>
      <FloatingPill><>Count: {mergedDocIds.length.toLocaleString()}</>
      </FloatingPill>
    </div>
  </StandardPage>;
});

const Header = React.memo(function Header({ query, setQuery }: { query: string; setQuery: (q: string) => void; }) {
  const rightSidebarHidden = useIsRightSidebarHidden();
  const history = useHistory();
  const searchPlaceholder = 'Search Readwise...';
  const shortcutsMap = useShortcutsMap();

  const [searchInput, setSearchInput, searchInputRef] = useStatePlusLiveValueRef<HTMLElement | null>(null);
  const focusField = useCallback(() => searchInputRef.current?.focus(), [searchInputRef]);

  useEffect(() => {
    (async () => {
      if (!searchInput) {
        return;
      }
      focusField();
    })();
  }, [focusField, searchInput]);
  useHotKeysPreventDefault(shortcutsMap[ShortcutId.Search], focusField);

  return (
    <>
      <div className={styles.searchWrapper}>
        <IoSearchOutline size={20} color="#000" className={styles.searchIcon} />
        {/* eslint-disable-next-line @shopify/react-require-autocomplete */}
        <input
          aria-labelledby="search-label"
          autoFocus
          id="search-field"
          ref={setSearchInput}
          autoComplete="off"
          type="search"
          className={styles.searchInput}
          placeholder={searchPlaceholder}
          value={query}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (isComposing(event)) {
              return;
            }
            if (event.key === 'Escape') {
              history.push(`/`);
            } else if (event.key === 'Enter' || event.keyCode === 40) {
              (event.target as HTMLInputElement).blur();
            }
          }}
          onChange={(event) => setQuery(event.target.value)}
        />
        <div className={styles.headerRight}>
          {rightSidebarHidden && <ToggleShowPannelsButton />}
        </div>
      </div>
    </>
  );
});
