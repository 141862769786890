import { useEffect, useMemo, useState } from 'react';

import { useCount } from './databaseHooks';
import { portalGate as portalGateToBackground } from './portalGates/toBackground/singleProcess';
import { getDocumentContentsIndexedCount } from './search.platformIncludingExtension';
import type { StoreItemEventArgument } from './types/events';

export function useContentIndexingProgress(query: string | undefined, isOnline: boolean): {
  contentIndexingMessage: string | undefined;
  percentIndexed: string | undefined;
} {
  const [contentLoadedState, setContentLoadedState] = useState<{
    hasLoadedAll: boolean;
    hasLoadedAny: boolean;
  } | undefined>();

  useEffect(() => {
    const callback = (state: {
      hasLoadedAll: boolean;
      hasLoadedAny: boolean;
    }) => setContentLoadedState(state);
    const listener = portalGateToBackground.on(
      'stores__documentContent__loaded-state-changed',
      callback as (args: StoreItemEventArgument) => void,
    );
    return () => {
      listener.off('stores__documentContent__loaded-state-changed', callback);
    };
  }, []);

  const [totalDocumentsToIndexCount, { isFetchingInitialInput: isFetchingTotalCount }] = useCount('documents', {
    selector: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'rxdbOnly.indexFields.triage_status_is_feed': false,
    },
  });

  const [documentContentsIndexedCount, setDocumentContentsIndexedCount] = useState<number | undefined>();

  useEffect(() => {
    const interval = setInterval(async () => {
      const count = await getDocumentContentsIndexedCount();
      setDocumentContentsIndexedCount(count);
    }, 2_000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const percentIndexedFromDatabases = useMemo(() => {
    if (documentContentsIndexedCount === undefined) {
      return undefined;
    }
    if (isFetchingTotalCount) {
      return undefined;
    }
    if (totalDocumentsToIndexCount === 0) {
      // user has no non-feed docs?? weird i guess but let's just be safe and return undefined.
      return undefined;
    }
    return documentContentsIndexedCount / totalDocumentsToIndexCount * 100;
  }, [documentContentsIndexedCount, isFetchingTotalCount, totalDocumentsToIndexCount]);

  let contentIndexingMessage: string | undefined;
  let percentIndexed: string | undefined;
  if (
    !query ||
    !contentLoadedState ||
    contentLoadedState.hasLoadedAll
  ) {
    contentIndexingMessage = undefined;
  } else if (isOnline) {
    contentIndexingMessage = `Search results may not include all matches until Reader finishes indexing your library.`;
    percentIndexed = percentIndexedFromDatabases?.toFixed() ?? '…';
  } else if (contentLoadedState.hasLoadedAny) {
    contentIndexingMessage = 'Some results may be missing, some of your documents were not downloaded yet. You are currently offline.';
  } else {
    contentIndexingMessage = 'Warning: no documents downloaded yet. You are currently offline.';
  }
  return { contentIndexingMessage, percentIndexed };
}
