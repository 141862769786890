import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// This doesn't import the `.platform` file because the interface isn't consistent
// eslint-disable-next-line restrict-imports/restrict-imports
import * as Search from '../../../../shared/foreground/search.web';
import useThrottle from '../../../../shared/utils/useThrottle';

/*
 * A convenience hook to allow populating search results based on user input.
 * This hooks provides state and methods to update the query + return results.
 */
export function useSearch(params: URLSearchParams, queryParam: string): {
  documentResults: Search.DocumentSearchResult[];
  contentResults: Search.ContentSearchResult[];
  query: string;
  setQuery: (value: (((prevState: string) => string) | string)) => void;
} {
  // query
  const q = params.get(queryParam) || '';

  const [query, setQuery] = useState(q);
  const [throttledQuery, setOriginalThrottledQuery] = useState(query);
  const setThrottledQuery = useThrottle(setOriginalThrottledQuery, 500, { leading: false, trailing: true });

  // update history
  const history = useHistory();
  useEffect(() => {
    setThrottledQuery(query);
  }, [query, setThrottledQuery]);

  // search + results
  const [documentResults, setDocumentResults] = useState<Search.DocumentSearchResult[]>([]);
  const [contentResults, setContentResults] = useState<Search.ContentSearchResult[]>([]);

  useEffect(() => {
    // replace search param in browser history
    const param = throttledQuery.length > 0 ? `?q=${encodeURIComponent(throttledQuery)}` : '';
    history.replace({ search: param });

    Search.searchDocuments(throttledQuery, (results) => setDocumentResults(results));
    Search.searchContent(throttledQuery, (results) => setContentResults(results));
  }, [history, setContentResults, setDocumentResults, throttledQuery]);

  return { query, setQuery, documentResults, contentResults };
}
